
* {
  box-sizing: border-box;
  font-family: 'Arvo', 'arial', sans-serif;
  line-height: 1.5rem;
}

/* You can add global styles to this file, and also import other style files */

.p-button {
  margin-right: 0.5rem;
}

.input-section{

}
.float-input {
  margin-top: 1.6rem;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.logo-image {
  max-height: 40px;
  max-width: 40px;
  vertical-align: middle;
  margin-right: 20px;
}

.logo-image-link {
  font-size: 20px;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.card-container {
  margin-top: 80px;
}

body {
  display: flex;
  min-height: 98.38vh;
  flex-direction: column;
  //font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  //font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
  font: 16px/1.65em "Rokkitt", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

body .ui-widget, body .ui-widget .ui-widget, body .ui-widget input, body .ui-widget select, body .ui-widget textarea, body .ui-widget button {
  font: 16px/1.65em "Rokkitt", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

body .ui-state-disabled, body .ui-widget:disabled {
  opacity: 0.7;
}

pre {
  font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  //font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
}

main {
  flex: 1 0 auto;
}

.inverted {
  filter: invert(100%);
}

.cfx-container {
  padding: 0 20px;
  max-width: 1200px;
}

.cfx-dashboard {
  margin: 40px auto;
}

.cfx-card {
  margin: 40px auto 0 auto;
  min-height: 400px;
  max-width: 400px;
}

.cfx-card.big {
  max-width: 750px;
}

//.cfx-card a, .cfx-card a:visited {
//  margin-left: 16px;
//  color: #ffffff;
//  line-height: normal;
//}

.cfx-card.text {
  text-align: center;
}

.cfx-navbar {
  height: 45px;
  background: #ffffff;
  color: #55606e;
  border-bottom: 1px solid #e1e1e1;
}
.cfx-navbar.cfx-container, .cfx-footer.cfx-container {
  max-width: unset;
}

.cfx-navbar a, .cfx-navbar a:visited, .cfx-navbar .cfx-nav-links {
  color: #55606e;
  text-decoration: none;
  display: inline-block;
  line-height: 45px;
}

.cfx-nav-links {
  float: right;
}

.cfx-nav-links a {
  padding: 0 5px;
  cursor: pointer;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.cfx-nav-links a:hover, .logo-image-link:hover {
  border-bottom: 2px solid #71AB6B;
}

.cfx-footer {
  min-height: 80px;
  font-size: 11px;
  background: #161a1c;
  color: #e4e6f0;
}

.cfx-footer-link {
  cursor: pointer;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  color: #fff;
  text-decoration: none;
  margin-left: 5px;
}

.footer-right {
  margin-left: auto;
}

.cfx-footer-link:hover, .cfx-footer-link a:hover {
  color: #71AB6B;
}

.cfx-dropdown-label {
  font-size: 16px;
}

.ui-button.ui-button-cfx {
  background-color: #71AB6B;
  border-color: #4F784B;
  color: #fff;
}

.ui-button.ui-button-cfx:enabled:hover, .ui-button.ui-button-cfx:enabled:focus {
  background-color: #4F784B;
}

.ui-accordion-header a {
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  color: #55606e;
}

.ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: #71AB6B !important;
  border: 1px solid #71AB6B !important;
}

.ui-accordion-header span {
  color: #71AB6B;
}

.ui-accordion-header.ui-state-active span {
  color: #e4e6f0;
}

.ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #71AB6B !important;
  border: 1px solid #71AB6B !important;
}

.ui-tabview .ui-tabview-panels .ui-tabview-panel{
  overflow-x: auto;
}
.ui-datagrid .ui-datagrid-content{
  border: none !important;
}
@media(max-width: 768px) {
  .cfx-navbar {
    height: 90px;
  }
  .cfx-container {
    margin: 0 3%;
    padding: 0;
  }
  .logo-image-link {
    margin-right: 10px;
    font-size: 14px;
  }
  .logo-image {
    margin-right: 10px !important;
  }
  .cfx-nav-links {
    float: none !important;
    margin: 0 !important;
    border-top: 1px solid #fff;
    display: block !important;
  }
  .cfx-nav-links a {
    padding: 0 3px !important;
    font-size: 14px;
  }
}
